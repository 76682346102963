import WPhoto_LiftedTopPhotoComponent from '@wix/thunderbolt-elements/src/components/WPhoto/viewer/skinComps/BasicWPhoto/LiftedTopPhoto.skin';
import WPhoto_LiftedTopPhotoController from '@wix/thunderbolt-elements/src/components/WPhoto/viewer/WPhoto.controller';


const WPhoto_LiftedTopPhoto = {
  component: WPhoto_LiftedTopPhotoComponent,
  controller: WPhoto_LiftedTopPhotoController
};


export const components = {
  ['WPhoto_LiftedTopPhoto']: WPhoto_LiftedTopPhoto
};


// temporary export
export const version = "1.0.0"
